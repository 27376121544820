// React imports

import { useEffect, useState } from 'react'
import useIsMounted from '~/hooks/useIsMounted'
import useWindowSize from '~/hooks/useWindowSize'

// Remix imports

// Third party imports

// Generated imports

// App imports

const useIsMd = () => {
  const { isMounted } = useIsMounted()
  const [isMd, setIsMd] = useState(true)
  const { windowWidth } = useWindowSize()

  useEffect(() => {
    let result = false
    if (isMounted) {
      if (windowWidth >= 768 && windowWidth < 1150) {
        result = true
      }
    }
    setIsMd(result)
  }, [isMounted, setIsMd, windowWidth])

  return { isMd }
}

export default useIsMd
