// React imports
import React, { useEffect, useState } from 'react'

// Remix imports

// Third party imports

// Generated imports

// App imports
import Triangle from '~/components/icons/Triangle'
import FormError from '~/components/typography/FormError'
import P2 from '~/components/typography/P2'

export interface Props
  extends React.DetailedHTMLProps<
    React.SelectHTMLAttributes<HTMLSelectElement>,
    HTMLSelectElement
  > {
  cursor?: 'cursor-pointer' | 'cursor-not-allowed' | 'cursor-default'
  disabledOptions?: string[]
  error?: string
  options?: string[][]
  required?: boolean
  /** If you just want to render the label and triangle */
  hideSelect?: boolean
}

const InputSelectText: React.FC<Props> = ({
  children,
  cursor = 'cursor-default',
  className,
  disabled,
  disabledOptions,
  error,
  options,
  required,
  defaultValue,
  value,
  hideSelect,
  onChange,
  ...other
}) => {
  const [currentValue, setCurrentValue] = useState(defaultValue)

  useEffect(() => {
    if (value) {
      setCurrentValue(value)
    }
  }, [value])

  const { name } = other
  const id_ = `id_${name}`
  const errorId = `id_${name}_error`
  const getDisplayValue = (value: string) => {
    let result = '-----'
    options?.forEach((item) => {
      if (item[0] === value) {
        result = item[1]
      }
    })
    return result
  }

  return (
    <div className={'text-left' + (className ? ` ${className}` : '')}>
      <div className="relative inline-block">
        <label
          htmlFor={id_}
          className={`flex ${cursor} items-center`}
        >
          <div className="flex-auto ">
            <P2 className="mr-1 overflow-x-hidden text-ellipsis whitespace-nowrap">
              {getDisplayValue(`${currentValue}`)}
            </P2>
          </div>
          <div className="w-[15px] flex-none">
            <Triangle
              disabled={disabled}
              size={15}
              color="bg-black"
            />
          </div>
        </label>
        {!hideSelect && (
          <select
            aria-required={required}
            aria-invalid={!!error}
            aria-errormessage={errorId}
            id={id_}
            disabled={disabled}
            name={name}
            className={
              'absolute top-0 left-0 bottom-0 right-0 h-inputNormal appearance-none border-none bg-white font-sansSerif text-p2 leading-none antialiased opacity-0 disabled:cursor-not-allowed disabled:bg-gray-bg md:text-p2md'
            }
            defaultValue={defaultValue}
            value={defaultValue ? undefined : currentValue}
            onChange={(e) => {
              setCurrentValue(e.target.value)
              if (onChange) {
                onChange(e)
              }
            }}
            {...other}
          >
            {options?.map((item) => (
              <option
                disabled={
                  disabledOptions ? disabledOptions.includes(item[0]) : false
                }
                key={item[0]}
                value={item[0]}
              >
                {item[1]}
              </option>
            ))}
          </select>
        )}
      </div>
      {name && (
        <FormError
          className="block"
          id={errorId}
          error={error}
        />
      )}
    </div>
  )
}

export default InputSelectText
